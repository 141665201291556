import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../layouts"
import SEO from "../components/seo"
import Heading from "../components/Heading"
// import SectionLink from "../components/SectionLink"

const BespokeText = styled.p`
  max-width: 1000px;
  text-align: center;
  margin: 100px 0;
  padding: 0 10px;
  font-size: 1.5rem;
  line-height: 2.5rem;
`

// const StyledLink = styled(Link)`
//   border-radius: 10px;
//   margin: 0 auto 100px;
//   background: #ff5400;
//   padding: 15px;
//   font-size: calc(1vw + 1rem);
//   color: #e7dfd4;
//   font-weight: 300;
//   transition: 0.3s ease all;
//   border: 2px #ff5400 solid;
//   box-sizing: border-box;
//   display: inline;
//   &:hover {
//     background: none;
//     color: #ff5400;
//   }
// `

const Blog = () => (
  <Layout>
    <SEO title="About" />
    <Heading>Blog</Heading>
    <BespokeText>Coming soon!</BespokeText>
  </Layout>
)

export default Blog
